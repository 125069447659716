@import "../../config/variables";

.modal-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform: translate(0, -100%);
  transition: transform .15s ease-out;
  overflow: auto;
  z-index: 999999;

  * {
    width: auto;
  }

  &.open {
    transform: translate(0, 0);
  }

  &-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 29, 64, 0.80);
    cursor: pointer;
    overflow: auto;
    z-index: 999998;
  }

  &__in {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 30px;

    @media (max-width: $xs) {
      padding: 15px;
    }
  }

  &__main {
    position: relative;
    width: 100%;
    max-width: 970px;
    border-radius: 16px;
    box-shadow: 0 0 30px 0 rgba(77, 77, 77, 0.10);
    cursor: auto;
    animation: fade .8s;
    overflow: hidden;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .2s ease;

    &:hover {
      opacity: .7;
    }
  }

  &__media {

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__content {

    p {
      font-size: 17px;
      line-height: 1.5;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__per {

    a, a * {
      display: inline;
    }
  }
}
