.modal-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform: translate(0, -100%);
  transition: transform 0.15s ease-out;
  overflow: auto;
  z-index: 999999;
}
.modal-main * {
  width: auto;
}
.modal-main.open {
  transform: translate(0, 0);
}
.modal-main-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 29, 64, 0.8);
  cursor: pointer;
  overflow: auto;
  z-index: 999998;
}
.modal-main__in {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 30px;
}
@media (max-width: 575px) {
  .modal-main__in {
    padding: 15px;
  }
}
.modal-main__main {
  position: relative;
  width: 100%;
  max-width: 970px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(77, 77, 77, 0.1);
  cursor: auto;
  animation: fade 0.8s;
  overflow: hidden;
}
.modal-main__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.modal-main__close:hover {
  opacity: 0.7;
}
.modal-main__media img {
  display: block;
  max-width: 100%;
  height: auto;
}
.modal-main__content p {
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 25px;
}
.modal-main__content p:last-child {
  margin-bottom: 0;
}
.modal-main__per a, .modal-main__per a * {
  display: inline;
}